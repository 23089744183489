<template>
  <div>
    <a-collapse v-model="activePayslip" :accordion="true">
      <a-collapse-panel
        v-for="payslip in payslips"
        :key="payslip.id"
        :header="payslip.title"
      >
      <a-spin :spinning="payslip.loading">
        <!-- Display payslip details here -->
        <div class="row">
          <div class="col-md-2">
            <!-- <a-avatar :size="64" icon="user" :src="payslip.user.imageUrl"/> -->
          </div>
          <div class="col-md-10">
            <h4>{{ payslip.user.employeeID }} {{payslip.user.fullName }} {{ payslip.user.department }}</h4>
          </div>
          <div class="col-md-12">
            <a-table :columns="payLineColumns" :data-source="payslip.payLines" bordered class="mt-3" :pagination="false">
              <template slot="sum" slot-scope="text, record">
                {{ Number(record.Amount) * Number(record.Count) }}
              </template>
              <template slot="footer">
                Total: {{ payslip.totalAmount }}
              </template>
            </a-table>
            <a-divider />
            <a-table :columns="transactionsColumns" :data-source="payslip.transactions" bordered class="mt-3" :pagination="false">
            </a-table>
          </div>
        </div>
      </a-spin>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import moment from 'moment'
import { fetchWageTypeNames, getWageTypeName } from '@/services/common/payroll'
import { firestore } from '@/services/firebase'

export default {
  props: {
    payslips: {
      type: Array,
      required: true,
    },
  },
  watch: {
    activePayslip: {
      handler() {
        if (this.activePayslip) {
          this.handleCollapseChange(this.activePayslip)
        }
      },
      deep: true,
    },
  },
  methods: {
    moment,
    async handleCollapseChange(activePayslipId) {
      const payslip = this.payslips.find(p => p.id === activePayslipId)
      if (!payslip.transactions) {
        payslip.loading = true
        const transactions = await this.fetchTransactions(payslip.transactionIDs)
        payslip.transactions = transactions
        this.$forceUpdate()
        payslip.loading = false
      }
    },
    async fetchTransactions(transactionIDs) {
      const transactions = []
      for (const id of transactionIDs) {
        const snapshot = await firestore.collection('payrollTransactions').doc(id).get()
        if (snapshot.exists) {
          const transaction = snapshot.data()
          transaction.id = snapshot.id
          transactions.push(transaction)
        }
      }
      return transactions
    },
  },
  async mounted() {
    const { wageTypeNames } = await fetchWageTypeNames()
    this.wageTypeNames = wageTypeNames
  },
  data() {
    return {
      activePayslip: null,
      wageTypeNames: [],
      transactions: [],
      payLineColumns: [
        {
          title: 'Lønnsrader',
          dataIndex: 'WageTypeNumber',
          customRender: (text) => getWageTypeName(text, this.wageTypeNames),
        },
        {
          title: 'Prosjekt',
          dataIndex: 'ProjectNumber',
        },
        {
          title: 'Avdeling',
          dataIndex: 'DepartmentNumber',
        },
        {
          title: 'Antall',
          dataIndex: 'Count',
        },
        {
          title: 'Sats',
          dataIndex: 'Amount',
        },
        {
          title: 'Til utbetaling',
          dataIndex: 'sum',
          scopedSlots: { customRender: 'sum' },
        },
      ],
      transactionsColumns: [
        {
          title: 'type',
          dataIndex: 'type',
        },
        {
          title: 'Order ID',
          dataIndex: 'orderID',
        },
        {
          title: 'Orderlinje ID',
          dataIndex: 'orderLineID',
        },
        {
          title: 'Dato Opprettet',
          dataIndex: 'createdDate',
          customRender: (text) => String(moment(text).format('DD.MM.YYYY')),
        },
        {
          title: 'Beskrivelse',
          dataIndex: 'comment',
        },
        {
          title: 'Kampanje',
          dataIndex: 'campaign',
        },
        {
          title: 'Prosjekt',
          dataIndex: 'project',
          scopedSlots: { customRender: 'project' },
        },
        {
          title: 'Avdeling',
          dataIndex: 'department',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Utbetaling',
          dataIndex: 'amount',
          scopedSlots: { customRender: 'name' },
        },
      ],
    }
  },
}
</script>
